* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.card-title,
.contact-card-header {
  font-family: "Times New Roman", Times, serif;
}

.portfolio-gitHub-logo {
  position: absolute;
  background-color: #11100b;
  padding: 1rem 0.5rem 3rem 3rem;
  right: 0rem;
  top: 6vh;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
}

/* changing navbar color */
.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(12,244,103, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler.navbar-toggler {
  border-color: rgb(12, 244, 103);
}
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

/* navbar */
.navbar {
  background-color: #11100b;
}
.logo-img {
  width: 30vw;
  max-width: 12rem;
}

.hero-section {
  background: linear-gradient(rgba(7, 7, 7, 0.5), rgba(0, 0, 0, 0.1)),
    url("./images/hero-background.jpg");
  height: 85vh;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  background-position: 60%;
}
a {
  color: white !important;
  text-decoration: none !important;
}

.work-btn {
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #2297f3;
  padding: 10px 20px;
  border: none;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 50px;
  transition: 695ms;
  transform: translateY(0);
  cursor: pointer;
}

.work-btn:hover {
  transition: 695ms;
  padding: 10px 41px;
  transform: translateY(-0px);
  background-color: #34da86;
  color: #ffffff;
  border: solid 2px #34da86;
}

.QA-BTN {
  font-size: 14px !important;
  color: #fff !important;
  background-color: #c10fcb !important;
  padding: 10px 20px !important;
  border: none !important;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px !important;
  border-radius: 50px !important;
  transition: 695ms !important;
  transform: translateY(0) !important;
  cursor: pointer !important;
}
.QA-BTN:hover {
  transition: 695ms !important;
  padding: 10px 41px !important;
  transform: translateY(-0px) !important;
  background-color: #34da86 !important;
  color: #ffffff !important;
  border: solid 2px #34da86 !important;
}
/* About me section */
.about-logo {
  width: 4rem;
}
.about-thumnail {
  display: none;
}
.toolbox-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.toolBTN-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.toolBTN {
  font-weight: 0 !important;
  font-size: 14px !important;
  color: #fff !important;
  background-color: #2297f3 !important;
  padding: 10px 20px !important;
  border: none !important;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px !important;
  border-radius: 50px !important;
  transition: 695ms !important;
  transform: translateY(0) !important;
  cursor: pointer !important;
}
.toolBTN:hover {
  transition: 695ms !important;
  padding: 10px 41px !important;
  transform: translateY(-0px) !important;
  background-color: #34da86 !important;
  color: #ffffff !important;
  border: solid 2px #34da86 !important;
}
/* cards */
.project-section {
  background-color: #0b0c11;
}
.project-buttons {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 3vh;
}

.cards-container a {
  text-decoration: none;
  color: white;
}
.card {
  width: 80vw;
  margin: auto;
  -webkit-box-shadow: 0px 0px 17px 0px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 17px 0px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 17px 0px rgba(255, 255, 255, 1);
}
.card:hover {
  transform: scale(1.05);
  transition: all 0.4s ease-in;
}
.card-title {
  color: #2aa769;
  font-size: 1.8rem !important;
}
.card-description {
  color: #0b0c11;
  font-size: 1.1rem;
}
.card-description img {
  width: 1.4rem;
}

.card-list {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  font-weight: 500;
  font-size: 0.9rem;
  color: #11100b;
}

/* contact */
.contact-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-container {
  height: 40vh;
}
.link {
  text-decoration: none;
  color: black;
}
.alert:hover {
  transform: scale(1.2);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.alert-linkedin {
  background-color: #2297f3 !important;
}
.alert-email {
  background-color: #4a00a0 !important;
  border: none !important;
}
/* Footer */

.footer {
  background-color: #0b0c11;
  color: white;
}
.footer-inner {
  display: flex;
  justify-content: space-between;
}

/* media queries */
@media screen and (min-width: 500px) {
  /* project */
  .card {
    width: 25rem;
  }
}

@media screen and (min-width: 768px) {
  /* navbar */
  .logo-img {
    width: 18vw;
  }
  /* about me */
  .about-thumnail {
    display: block;
  }

  .toolBTN {
    width: 20vw;
  }

  /* project */
  .cards-container {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 3vh;
    grid-column-gap: 1.5vh;
  }
  .card {
    width: 100%;
    min-height: 100%;
  }
}

@media screen and (min-width: 992px) {
  /* navbar */
  .logo-img {
    width: 12vw;
  }

  .cards-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
